


<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="black">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#13736f"
      spinner="spinner" />

    <v-layout wrap pt-4 pb-7 justify-end>
      <v-flex xs11>
        <v-layout wrap justify-center style="font-family: mainfont; font-size: 16px; color: black">
          <v-flex xs12 lg11 pa-5>
            <v-card elevation="0" class="pt-8 pb-8 pl-16">
              <v-layout wrap justify-center>
                <v-flex lg11 xs12>
                  <v-layout wrap>
                    <v-flex xs11 pb-5 class="mainfont" style="color: black; font-size: 15px; font-weight: bold">Order
                      Info</v-flex>
                    <v-flex xs4 lg4>
                      <span style="
                            font-family: mainfont;
                            font-size: 13px;
  
                            color: #515151;
                            font-weight: bold;
                          ">ORDER BY</span>
                    </v-flex>

                    <v-flex xs4 lg4>
                      <span style="
                            font-family: mainfont;
                            font-size: 13px;
  
                            color: #515151;
                            font-weight: bold;
                          ">ORDER DATE</span>
                    </v-flex>
                    <v-flex xs4 lg4>
                      <span style="
                            font-family: mainfont;
                            font-size: 13px;
  
                            color: #515151;
                            font-weight: bold;
                          ">PHONE</span>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap v-if="list && list.uid">
                    <v-flex xs4 v-if="list.uid.role === 'Customer'">
                      <span class="mainfont" style="color: black; font-size: 14px">{{ list.uid.customerId.name }}
                      </span>
                    </v-flex>

                    <v-flex xs4 v-if="list.uid.role === 'Trader'">
                      <span class="mainfont" style="color: black; font-size: 14px">{{ list.uid.traderId.name }}
                      </span>
                    </v-flex>

                    <v-flex xs4 v-if="list.uid.role === 'Farmer'">
                      <span class="mainfont" style="color: black; font-size: 14px">{{ list.uid.farmerId.name }}
                      </span>
                    </v-flex>

                    <v-flex xs4 v-if="list && list.orderID">
                      <span style="
                            font-family: mainfont;
                            font-size: 14px;
                            color: black;
                          ">
                        {{ list.orderID }}
                      </span>
                    </v-flex>

                    <v-flex xs4>
                      <span style="
                            font-family: mainfont;
                            font-size: 14px;
                            color: black;
                          " v-if="list && list.create_date">
                        {{ formatDate(new Date(list.create_date)) }}
                      </span>
                    </v-flex>

                    <v-flex xs4 v-if="list && list.uid && list.uid.phone">
                      <span style="
                            font-family: mainfont;
                            font-size: 14px;
                            color: black;
                          ">
                        {{ list.uid.phone }}
                      </span>
                    </v-flex>
                  </v-layout>

                  <v-flex xs10 pt-6 pr-8>
                    <v-divider></v-divider>
                  </v-flex>
                  <v-layout pt-6 wrap justify-start>
                    <v-flex xs11 pb-5 class="mainfont" style="color: black; font-size: 15px; font-weight: bold">
                      Address</v-flex>
                    <v-flex xs4>
                      <span style="
                            font-family: mainfont;
                            font-size: 13px;
  
                            color: #515151;
                            font-weight: bold;
                          ">
                        NAME</span>
                    </v-flex>
                    <v-flex xs4>
                      <span style="
                            font-family: mainfont;
                            font-size: 13px;
  
                            color: #515151;
                            font-weight: bold;
                          ">ADDRESS</span>
                    </v-flex>
                    <v-flex xs4>
                      <span style="
                            font-family: mainfont;
                            font-size: 13px;
                            color: #515151;
                            font-weight: bold;
                          ">CONTACT NUMBER</span>
                    </v-flex>
                  </v-layout>

                  <v-layout wrap justify-start>
                    <v-flex xs4 v-if="list">
                      <span v-if="list">
                        <span style="
                              font-family: mainfont;
                              font-size: 14px;
                              color: black;
                            " v-if="list.name">
                          {{ list.name }}
                        </span>
                      </span>
                    </v-flex>

                    <v-flex xs4 v-if="list">
                      <span v-if="list">
                        <span style="
                              font-family: mainfont;
                              font-size: 14px;
                              color: black;
                            " v-if="list.address">
                          {{ list.address }}
                          {{ list.city }}
                          <br />

                          {{ list.pincode }}
                          {{ list.state }}
                        </span>
                      </span>
                    </v-flex>

                    <v-flex xs4 v-if="list">
                      <span v-if="list">
                        <span style="
                              font-family: mainfont;
                              font-size: 14px;
                              color: black;
                            " v-if="list.phone">
                          {{ list.phone }}
                        </span>
                      </span>
                    </v-flex>
                  </v-layout>
                  <v-flex xs1 pt-6 pr-8>
                    <v-divider></v-divider>
                  </v-flex>

                  <v-layout pt-6 wrap justify-start>
                    <v-flex xs11 pb-5 class="mainfont" style="color: black; font-size: 15px; font-weight: bold">
                      Product Details</v-flex>
                    <v-flex xs4>
                      <v-avatar size="80px">
                        <v-img v-if="list.categoryId && list.categoryId.icon"
                          :src="mediaURLnew + list.categoryId.icon"></v-img>
                      </v-avatar>

                    </v-flex>

                    <v-flex xs4 pt-4 text-left>
                      <span style="
                            font-family: mainfont;
                            font-size: 13px;
  
                            color: #515151;
                            font-weight: bold;
                          ">CATEGORY</span>
                      <br />
                      <span v-if="list.categoryId && list.categoryId.name" class="mainfont"
                        style="color: black; font-size: 14px">{{ list.categoryId.name }}
                      </span>
                    </v-flex>
                    <v-flex text-left pt-4 xs4>
                      <span style="
                            font-family: mainfont;
                            font-size: 13px;
                            color: #515151;
                            font-weight: bold;
                          ">WEIGHT</span>
                      <br />
                      <span v-if="list.categoryId && list.categoryId.name" class="fontpop3">{{ list.weight }}&nbsp;{{
                        list.weightType }} </span>
                    </v-flex>
                  </v-layout>


                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
      
<script>
import axios from "axios";
import { mdiChevronDown } from "@mdi/js";

export default {
  data() {
    return {
      itemArray: ["Cancelled", "Shipped", "Delivered"],
      title: null,
      content: [],
      photos: [],
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      dialog2: false,
      deletedialog: false,
      imageArray: [],
      imageError: "",
      editdailog: false,
      list: {},
      imageCoverError: "",
      formData: new FormData(),
      formData2: new FormData(),
      id: this.$route.query.id,
      items: [],
      selectedFiles: [],
      curid: [],
      image: null,
      coverImageArray: [],
      role: null,
      appLoading: false,
      list2: {
        orderStatus: "Shipped",
      },
      formDataCover: new FormData(),
      selectedImage: null,
      topimageFile: null,
      producttopimagefile: null,
      productrightimagefile: null,
      imageFiles: [],
    };
  },
  mounted() {
    this.getlist();
  },
  created() {
    this.currentPage = parseInt(this.$route.query.currentPage);
  },
  computed: {
    chevronDownIcon() {
      return mdiChevronDown;
    },
    filteredItemArray() {
      if (this.list.orderStatus === "Shipped") {
        return this.itemArray.filter((item) => item === "Delivered");
      } else {
        return this.itemArray;
      }
    },
  },
  methods: {
    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    getlist() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/custom/order/view/" + this.id,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.list = response.data.data;
          this.items = response.data.items;
          this.appLoading = false;
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    downloadInvoice() {
      const token = localStorage.getItem("token");
      const downloadUrl = "/order/invoice/pdf";
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          orderId: this.id,
        },
        responseType: "blob",
      };

      axios
        .get(downloadUrl, config)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "invoice.pdf");
          document.body.appendChild(link);

          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error("Error downloading invoice:", error);
        });
    },
    changeOrderstatus() {
      this.appLoading = true;
      axios({
        method: "POST",

        url: "/admin/order/status/change",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          orderId: this.id,
          orderStatus: this.list.orderStatus,
        },
      });
      this.$router.push({
        path: "/StorePage",
      });
      this.appLoading = false;
    },
  },
};
</script>
<style>
.icon {
  padding-left: 170px;
}

.text {
  text-align: left;
  font-family: poppinsRegular;
  font-size: 14px;
  letter-spacing: 0px;
  color: #9e9e9e;
}

.v-application .primary {
  background-color: red !important;
}

.flex-red {
  background-color: #13736f;
}

.flex-g {
  background-color: #13736f;
}

.bg8 {
  background: var(--grend,
      linear-gradient(252deg,
        #e4ecee 0%,
        #9bc2c2 0%,
        #6ca6a5 28%,
        #157470 88%,
        #13736f 100%));
}

.sharpEdges {
  border-radius: 0px;
  /* Add any other styles for the card here if needed */
}

.height-wrapper {
  height: 75px;
  /* Set the desired height (40px in this example) */
  display: flex;
  align-items: center;
  /* Vertically center the content */
}

.cus2 {
  background: var(--grend,
      linear-gradient(252deg,
        #e4ecee 0%,
        #9bc2c2 0%,
        #6ca6a5 28%,
        #157470 88%,
        #13736f 100%));
}

.cus {
  background-color: #13736f;
}
</style>